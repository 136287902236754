@import "variables";

.course-list {
	width: 100%;
	margin-top: map-get($spacers, 4);
}

.course-list__title {
	margin: 0 0 $spacer 0;
}

.course-list__list {
	display: grid;
	grid-template-columns: 100%;
	justify-content: stretch;
	align-content: stretch;
	grid-gap: $spacer;
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.course-list__course {
	grid-column-start: span 1;
	display: flex;
	width: 100%;
}

@media (min-width: $breakpoint-md) {
	.course-list__list {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (min-width: $breakpoint-xxl) {
	.course-list__list {
		grid-template-columns: repeat(3, 1fr);
	}
}