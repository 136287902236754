@import "variables";
@import "mixins";

.journey {
	background-color: $white;
	padding: $gutter / 2;
	grid-column-start: span 1;

	@include drop-shadow;
}

.journey-parent {
	width: 100%;
	padding: 0;
	box-shadow: none;
	display: grid;
	grid-template-columns: 100%;
	justify-content: stretch;
	align-content: stretch;
	grid-gap: $spacer;
}

.journey-parent__heading {
	grid-column-start: span 1;
	margin: 0;
}

.journey__header {
	margin-bottom: map-get($spacers, 1);
}

.journey__heading {
	font-size: 2rem;
	font-weight: 400;
	margin-top: 0;
	margin-bottom: map-get($spacers, 1);
	color: map-get($theme-colors, "primary-light");
}
.journey__heading--sub {
	font-size: 1.5rem;
	word-wrap: normal;
	color: $gray-600;
}

@media (min-width: $breakpoint-lg) {
	.journey {
		padding: $gutter;
	}
}

@media (min-width: $breakpoint-xxl) {
	.journey-parent {
		grid-template-columns: repeat(2, 1fr);
	}
	.journey-parent__heading {
		grid-column-start: span 2;
	}
}