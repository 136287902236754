@import "variables";

$padding-y: map-get($spacers, 5);
$padding-x: map-get($spacers, 3);

.post__footer {
	padding-top: $padding-y;
	border-bottom: $border $gray-600;
}

.post__title {
	margin-top: map-get($spacers, 4);
}

.post__author {
	font-size: $font-size-sm;
	font-style: italic;
	padding-left: map-get($spacers, 3);
}

.post__social-share-heading {
	font-size: $font-size-sm;
	text-align: center;
}

.post__header-image {
	width: 100%;
	height: auto;
	display: block;
	margin: map-get($spacers, 3) auto;
}

.post__intro-message {
	margin: map-get($spacers, 2) 0;
	font-size: $font-size-sm;
}

.post__post-nav {
	display: grid;
	grid-template-columns: 100%;
	margin-top: map-get($spacers, 3);
	grid-gap: $spacer;
}

.post__previous-post {
	order: 2;
}

.post__next-post {
	order: 1;
}

.post__course-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.post__course-title {
	color: map-get($theme-colors, "primary");
	margin-top: map-get($spacers, 3);
}

.post__heading {
	margin-top: map-get($spacers, 3);
}

.post__heading--2 {
	border-bottom: $border $black;
	margin-top: map-get($spacers, 4);
	padding-bottom: map-get($spacers, 1);
}

.post__figure {
	margin-left: 0;
	margin-right: 0;
}

.post__image {
	margin: map-get($spacers, 3) 0;
}

.post__figure-caption {
	margin-bottom: map-get($spacers, 3);
	text-align: center;
}

.post__list {
	margin: map-get($spacers, 4) map-get($spacers, 3);
	padding-left: 0;
}

.post__list--unordered {
	list-style-type: "-  ";

	.post__list-item::marker {
		padding-right: 5px;
	}
}

.post__list--ordered {
	list-style-position: inside;
}

.post__list-item {
	margin-bottom: map-get($spacers, 1);
}

@media (min-width: $breakpoint-md) {
	.post__post-nav {
		grid-template-columns: repeat(2, 1fr);
	}
	.post__previous-post {
		order: 1;
	}
	.post__next-post {
		order: 2;
	}
}

@media (min-width: $breakpoint-lg) {
	.post {
		padding: $gutter (4 * $gutter);
	}
}