@import "variables";

.random-pic {
	width: 100%;
	margin-top: map-get($spacers, 3);
}

.random-pic__pic {
	width: 100%;
	height: auto;
	display: block;
}