@import "variables";
@import "mixins";

.course-card {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;

	@include drop-shadow;
}

.course-card__img {
	width: 100%;
	height: auto;
}

.course-card__body {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-content: space-between;
	background-color: $white;
	padding: map-get($spacers, 2);
}

.course-card__text-wrapper {
	flex-grow: 1;
}

.course-card__title {
	margin-top: 0;
}

.course-card__action,
.course-card__action:visited {
	$card-color: map-get($theme-colors, "secondary");

	border: $border $card-color;
	border-radius: $border-radius;
	color: $card-color;
	padding: map-get($spacers, 1);
	text-align: center;

	&:hover {
		background-color: $card-color;
		color: $white;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


