@import "variables";

$primary-color: map-get($theme-colors, 'primary');

html {
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: scroll;
	font-weight: $font-weight-base;
	font-family: $font-family-base;
	font-size: $font-size-base;
	color: $black;
	line-height: $line-height-base;
}

*, *:before, *:after {
	box-sizing: inherit;
}

html, body {
	width: 100%;
	max-width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 0;
	word-break: $word-break;
}

h1, h2, h3, h4, h5, h6 {
	color: $headings-color;
	font-weight: $headings-font-weight;
	font-family: $headings-font-family;
	line-height: $headings-line-height;
	margin-bottom: $headings-margin-bottom;
}
h1 {
	font-size: $h1-font-size;
}
h2 {
	font-size: $h2-font-size;
}
h3 {
	font-size: $h3-font-size;
}
h4 {
	font-size: $h4-font-size;
}
h5 {
	font-size: $h5-font-size;
}
h6 {
	font-size: $h6-font-size;
}

img {
	max-width: 100%;
}

code {
	color: $code-color;
	background-color: $code-background-color;
	padding: 0 map-get($spacers, 1) / 2;
}

blockquote {
	margin: 0;
	background-color: $white;
	border-left: $active-border-indicator $primary-color;
	padding: map-get($spacers, 3);
	position: relative;

	p {
		&:first-of-type {
			margin-top: 0;
		}
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

a, a:visited {
	color: $primary-color;
	text-decoration: none;

	&:hover {
		color: map-get($theme-colors, "secondary");
	}
}