@import "variables";

.about,
.course-list-nav {
	margin-bottom: map-get($spacers, 5);
}

.about__profile-pic {
	border-radius: 50%;
	display: block;
	width: 100%;
	max-width: 300px;
	height: auto;
	margin: map-get($spacers, 2) auto;
}

.about__profile-heading,
.course-list-nav__heading {
	margin: map-get($spacers, 3) 0;
}

.about__social-media {
	display: flex;
	list-style: none;
	padding-left: 0;
	font-size: $font-size-lg;
}
.about__social-media--centered {
	justify-content: center;
}

.about__social-media-link,
.course-list-nav__link {
	margin: 0 map-get($spacers, 1);
}

.course-list-nav__link {
	margin-top: map-get($spacers, 2);
}

.about__advert {
	display: block;
	width: 100%;
	height: auto;
}

.course-list-nav__links {
	display: flex;
	flex-direction: column;
	margin-bottom: map-get($spacers, 5);
	padding-left: 0;
}

ul.course-list-nav__links {
	list-style: none;
}

ol.course-list-nav__links {
	list-style-position: inside;
}
