@import "variables";

.footer {
	width: 100%;
	padding: map-get($spacers, 3);
	margin-top: map-get($spacers, 5);
	text-align: center;
	background-color: map-get($theme-colors, "primary");
	color: $white;
	font-size: $font-size-sm;
}

.footer__link {
	color: $white;
	text-decoration: underline;
}